import React from 'react';
import { Heading, Box } from '@chakra-ui/react'
import { formatPostDate } from '../utils/helpers';

const PostHeader = ({
    title,
    date,
    timeToRead,
    lang
}) => (
    <Box as="header" mb={6}>
        <Heading as="h1" size='xl'>
            {title}
        </Heading>
        <Box as="p"
            mb={1}
            mt={1}
        >
            {formatPostDate(date, lang)}
            {timeToRead ? ` • ${timeToRead}` : ''}
        </Box>
    </Box>
)

export default PostHeader;