import * as React from 'react';
import { Tweet } from 'react-twitter-widgets';
import { Link, Box, Flex, Spacer } from '@chakra-ui/react'
import { ExternalLinkIcon } from '@chakra-ui/icons'

const getTweetId = url => {
    const match = url.match(/\/(\d+)\/?$/);
    return match[1];
};

const TwitterPost = ({ post }) => {
    const { Author, Title, Note, URL, Article_type, isTwitter } = post.node.data;
    return (
        <Box mb={3}>
            <Link
                className="weekly-post-title"
                href={URL}
                color='teal.500'
                isExternal
            >
                {Title}  <ExternalLinkIcon mx='2px' />
            </Link>
            <Tweet
                tweetId={getTweetId(URL)}
                options={{
                    width: 'auto',
                    align: 'center',
                    conversation: 'none',
                    cards: 'hidden',
                }}
            />
            <div>
                {Author ? (
                    <small style={{ marginLeft: '20px' }}>
                        <i> — {Author}</i>
                    </small>
                ) : null}
            </div>
            <div class="prose">
                <div
                    dangerouslySetInnerHTML={{ __html: Note.childMarkdownRemark.html }}
                />
            </div>
        </Box>
    );
};

const WeeklyPost = ({ post }) => {
    const { Author, Title, Note, URL, Article_type, isTwitter } = post.node.data;
    let widget = null;
    // if (isTwitter) {
    //   return <TwitterPost post={post} />
    // }
    if (Article_type && Article_type.includes('Podcast')) {
        const widgetUrl = URL.split('/episode').join('/embed-podcast/episode');
        widget = (
            <iframe
                src={widgetUrl}
                width="100%"
                height="232"
                frameborder="0"
                allowtransparency="true"
                allow="encrypted-media"
            />
        );
    }

    return (
        <Box mb={3} borderBottom="1px" borderColor="blackAlpha.100">
            <Flex>
                <Link
                    href={URL}
                    color='teal.500'
                    isExternal
                    fontSize="xl"
                >
                    {Title}  <ExternalLinkIcon mx='2px' />
                </Link>
                <Spacer/>
                <Box textAlign="right">
                    {Author ? (
                        <small style={{ marginLeft: '20px' }}>
                            <i> — {Author}</i>
                        </small>
                    ) : null}
                </Box>
            </Flex>
            <div class="prose">
                <div
                    dangerouslySetInnerHTML={{ __html: Note.childMarkdownRemark.html }}
                />
            </div>
        </Box>
    );
};

export default WeeklyPost;
