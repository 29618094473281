import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Footer from '../components/Footer';
import PostHeader from '../components/PostHeader';
import WeeklyPost from '../components/WeeklyPost';
import { loadFontsForCode } from '../utils/i18n';
import { genWeekPost } from '../utils/seo';
import './blog-post.css';

class BlogPostTemplate extends React.Component {
    render() {
        const posts = this.props.data.posts.edges;
        let { week } = this.props.pageContext;
        const siteTitle = get(this.props, 'data.site.siteMetadata.title');
        const lang = 'zh-hant';
        const seoData = genWeekPost(week, posts);
        // Replace original links with translated when available.

        loadFontsForCode(lang);

        return (
            <Layout location={this.props.location} title={siteTitle} locale={lang}>
                <SEO
                    lang={lang}
                    title={seoData.title}
                    description={seoData.description}
                    slug={this.props.path}
                />
                <main className="weekly" style={{ minHeight: '60vh' }}>
                    <article>
                        <PostHeader
                            title={seoData.title}
                            date={week}
                            lang={lang}
                        />
                        {posts.map(post => (
                            <WeeklyPost post={post} key={post.url} />
                        ))}
                        {/* <div dangerouslySetInnerHTML={{ __html: html }} /> */}
                    </article>
                </main>
                <Footer locale={lang} />
            </Layout>
        );
    }
}

export default BlogPostTemplate;

export const pageQuery = graphql`
  query WeeklyPostBySlug($week: Date!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    posts: allAirtableList(
      filter: {
        data: { Week: { eq: $week }, Exclude: { ne: true } }
        queryName: { eq: "List" }
      }
      sort: { fields: data___sequence }
    ) {
      edges {
        node {
          id
          data {
            Note {
              id
              childMarkdownRemark {
                html
              }
            }
            isTwitter
            Author
            Title
            URL
            Article_type
          }
        }
      }
    }
  }
`;
